import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './LeftPanel.scss';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { ToolTip } from 'clm-components';
import { ReactComponent as AppDetailsIcon } from '../../../assests/icons/appDetails.svg';
import getIconForSubType from './utils';
import { summaryModuleId } from '../RecordUtils';

function LeftPanel({ selectedModule, handleModuleChange }) {
  const moduleData = useSelector((state) => state.appDetails.moduleData);
  const failureReason = useSelector((state) => state.appDetails.failureReason);

  const getActiveClassName = (moduleId) => (selectedModule === moduleId ? 'active' : '');

  const onModuleClick = (moduleId) => {
    handleModuleChange(moduleId);
  };

  useEffect(() => {
    const selectedModuleElement = document?.querySelector('#leftPanel__body__item.active');
    selectedModuleElement?.scrollIntoView({ behavior: 'instant', block: 'nearest' });
  }, [
    selectedModule,
  ]);

  return (
    <div id="record__left_panel">
      <div id="leftPanel__container">
        <div id="leftPanel__header">
          <h2>Summary</h2>
          <ToolTip title="Application details" placement="right">
            <div
              id="leftPanel__header__item"
              className={getActiveClassName(summaryModuleId)}
              onClick={() => onModuleClick(summaryModuleId)}
              aria-hidden
            >

              <AppDetailsIcon />
              <span>Application details</span>
            </div>
          </ToolTip>
        </div>
        <div id="leftPanel__body">
          <h2>Modules Used</h2>
          <div id="leftPanel__body__modules">
            {!isEmpty(moduleData) ? (
              Object.entries(moduleData).map(
                ([moduleId, { moduleName, isSuccess, subType }]) => (
                  <ToolTip title={moduleName} key={moduleId} placement="right">
                    <div
                      key={moduleId}
                      id="leftPanel__body__item"
                      className={getActiveClassName(moduleId)}
                      onClick={() => onModuleClick(moduleId)}
                      aria-hidden
                    >
                      {React.cloneElement(getIconForSubType(subType))}
                      <span>{moduleName}</span>
                      {!isSuccess && !failureReason && (
                        <div id="leftPanel__body__item__status__fail" />
                      )}
                    </div>
                  </ToolTip>
                ),
              )
            ) : (
              <span id="leftPanel__body__no_module">No modules attempted</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

LeftPanel.propTypes = {
  selectedModule: PropTypes.string.isRequired,
  handleModuleChange: PropTypes.func.isRequired,
};
export default LeftPanel;
