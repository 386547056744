/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import * as Sentry from '@sentry/react';
import { flatMap, get, isEmpty } from 'lodash';
import {
  updateStatus, updateMetaDataFilters, updateTimePeriod,
  updateTransactionMetadataFilters,
} from '../../../reducers/data';
import MultiSelectCard from '../../Common/Cards/MultiSelectCard';
import DatePickerCard from '../../Common/Cards/DatePickerCard';
import MultiInputCard from '../../Common/Cards/MultiInputCard';
import { formatDateForDropdown } from '../../../utils/dateFormats';
import errorCode from '../../../constants/errorCode';
import DynamicSelectWrapper from './DynamicSelectWrapper';
import { safeParseJSON } from '../../../utils/helpers';

export const searchFilterLabelGen = (details) => {
  const { value, label, contains } = details;
  const newValue = {
    text: value,
    contains,
  };
  const filterLabel = (
    <>
      {label}
      {' '}
      {contains ? 'is' : 'is not'}
      <span>{value}</span>
    </>
  );
  return { value: newValue, filterLabel };
};

export const dateFilterLabelGen = (details) => {
  const { label, selectedDateRange } = details;
  const filterLabel = (
    <>
      {label}
      {' '}
      is
      <span>
        {formatDateForDropdown(selectedDateRange.startTime, selectedDateRange.endTime)}
      </span>
    </>
  );
  return { value: selectedDateRange, filterLabel };
};

export const multiInputFilterLabelGen = (details) => {
  const { label: keyLabel, inputArray, contains } = details;
  const value = {
    values: inputArray,
    contains,
  };
  const filterLabel = (
    <>
      {keyLabel}
      {' '}
      {contains ? 'is' : 'is not'}
      {inputArray.map((item, idx) => (
        <>
          {idx !== 0 && 'or'}
          <span key={item}>{item}</span>
        </>
      ))}
    </>
  );
  return { value, filterLabel };
};

const getReviewerEmailOptionListMethod = (stateValue) => flatMap(stateValue, 'emails');

export const multiSelectFilterLabelGen = (details) => {
  const { label: keyLabel, selectedOptions, contains } = details;
  const value = {
    values: selectedOptions.map((option) => option.value),
    contains,
  };
  const filterLabel = (
    <>
      {keyLabel}
      {' '}
      {contains ? 'is' : 'is not'}
      {selectedOptions.map(({ label }, idx) => (
        <>
          {idx !== 0 && 'or'}
          <span key={label}>{label}</span>
        </>
      ))}
    </>
  );
  return { value, filterLabel };
};

export const fetchFilterList = async (filterConfig) => {
  try {
    const {
      statusOptions,
      // deviceOptions,
      // platformOptions,
      // platformVersionOptions,
      // sdkVersionOptions,
      countryOptions,
      // browserOptions,
      // osOptions,
    } = filterConfig || {};

    const availableFilterList = [
      {
        id: 'applicationFilters',
        name: 'Application Filters',
        filters: [
          {
            id: 'filter_card_item_created_at',
            key: 'createdAt',
            label: 'Date range',
            componentRenderer: (props) => (
              <DatePickerCard {...props} title="Date of creation" />
            ),
            updateFuntion: ({
              data,
              filterData,
            }) => updateTimePeriod({
              source: 'application',
              value: data.value,
              filterData,
            }),
            genLabelFunc: dateFilterLabelGen,
            isCancellable: false,
          },
          {
            id: 'filter_card_item_status',
            key: 'status',
            label: 'Application Status',
            componentRenderer: (props) => (
              <MultiSelectCard
                {...props}
                options={statusOptions || []}
                title="This filter shows the current state an application is in"
                placeholder="Select status"
              />
            ),
            updateFuntion: updateStatus,
            genLabelFunc: multiSelectFilterLabelGen,
            isCancellable: true,
          },
          {
            id: 'filter_card_item_workflow_id',
            key: 'workflowId',
            label: 'Workflow Name',
            componentRenderer: (props) => (
              <DynamicSelectWrapper
                {...props}
                stateKeyPath="user.workflowIdListWithDbData"
                title="This filter shows the workflow used for an application"
                placeholder="Select workflow Name"
                idPath="id"
                labelPath="name"
              />
            ),
            updateFuntion: updateMetaDataFilters,
            genLabelFunc: multiSelectFilterLabelGen,
            isCancellable: true,
          },
          {
            id: 'filter_card_item_reviewer_id',
            key: 'reviewerEmail',
            label: 'Assignee',
            componentRenderer: (props) => (
              <DynamicSelectWrapper
                {...props}
                stateKeyPath="data.reviewersList"
                title="This filter shows the list of assignees"
                placeholder="Select Assignee"
                idPath="id"
                labelPath="id"
                getOptionListMethod={getReviewerEmailOptionListMethod}
              />
            ),
            updateFuntion: updateMetaDataFilters,
            genLabelFunc: multiSelectFilterLabelGen,
            isCancellable: true,
          },
        ],
      },
      // TODO: enable later
      // {
      // id: 'deviceFilters',
      // name: 'Device Filters',
      // filters: [
      // {
      //   id: 'filter_card_item_browser',
      //   key: 'browser',
      //   label: 'Browser',
      //   componentRenderer: (props) => (
      //     <MultiSelectCard
      //       {...props}
      //       options={browserOptions || []}
      //       placeholder="Select browser"
      //       title="Browser"
      //     />
      //   ),
      //   updateFuntion: updateMetaDataFilters,
      //   genLabelFunc: multiSelectFilterLabelGen,
      //   isCancellable: true,
      // },
      // {
      //   id: 'filter_card_item_os',
      //   key: 'os',
      //   label: 'OS',
      //   componentRenderer: (props) => (
      //     <MultiSelectCard
      //       {...props}
      //       options={osOptions || []}
      //       placeholder="Select OS"
      //       title="OS of device"
      //     />
      //   ),
      //   updateFuntion: updateMetaDataFilters,
      //   genLabelFunc: multiSelectFilterLabelGen,
      //   isCancellable: true,
      // },
      // {
      //   id: 'filter_card_item_device',
      //   key: 'device',
      //   label: 'Device Brand',
      //   componentRenderer: (props) => (
      //     <MultiSelectCard
      //       {...props}
      //       options={deviceOptions || []}
      //       placeholder="Select device brand"
      //       title="Device"
      //     />
      //   ),
      //   updateFuntion: updateMetaDataFilters,
      //   genLabelFunc: multiSelectFilterLabelGen,
      //   isCancellable: true,
      // },
      // {
      //   id: 'filter_card_item_platform',
      //   key: 'platform',
      //   label: 'Platform',
      //   componentRenderer: (props) => (
      //     <MultiSelectCard
      //       {...props}
      //       options={platformOptions || []}
      //       placeholder="Select Platform"
      //       title="Platform of device"
      //     />
      //   ),
      //   updateFuntion: updateMetaDataFilters,
      //   genLabelFunc: multiSelectFilterLabelGen,
      //   isCancellable: true,
      // },
      // {
      //   id: 'filter_card_item_platform_version',
      //   key: 'platformVersion',
      //   label: 'Platform Version',
      //   componentRenderer: (props) => (
      //     <MultiSelectCard
      //       {...props}
      //       options={platformVersionOptions || []}
      //       placeholder="Select Platform Version"
      //       title="Platform Version"
      //     />
      //   ),
      //   updateFuntion: updateMetaDataFilters,
      //   genLabelFunc: multiSelectFilterLabelGen,
      //   isCancellable: true,
      // },
      // ],
      // },
      // TODO: enable later
      // {
      // id: 'sdkFilters',
      // name: 'SDK Filters',
      // filters: [
      //   {
      //     id: 'filter_card_item_sdk_version',
      //     key: 'sdkVersion',
      //     label: 'SDK Version',
      //     componentRenderer: (props) => (
      //       <MultiSelectCard
      //         {...props}
      //         options={sdkVersionOptions || []}
      //         placeholder="Select SDK Version"
      //         title="SDK Version"
      //       />
      //     ),
      //     updateFuntion: updateMetaDataFilters,
      //     genLabelFunc: multiSelectFilterLabelGen,
      //     isCancellable: true,
      //   },
      // {
      //   id: 'filter_card_item_app_version',
      //   key: 'appVersion',
      //   label: 'App Version',
      //   componentRenderer: (props) => (
      //     <MultiInputCard
      //       {...props}
      //       placeholder="Enter App Version"
      //       title="App Version"
      //     />
      //   ),
      //   updateFuntion: updateMetaDataFilters,
      //   genLabelFunc: multiInputFilterLabelGen,
      //   isCancellable: true,
      // },
      // ],
      // },
      {
        id: 'customerFilter',
        name: 'Customer Filters',
        filters: [
          {
            id: 'filter_card_item_ip_address',
            key: 'ipAddress',
            label: 'IP Address',
            componentRenderer: (props) => (
              <MultiInputCard
                {...props}
                data-hj-suppress
                placeholder="Type IP Address and Enter"
                title="Filter by the IP address of customers"
              />
            ),
            updateFuntion: updateMetaDataFilters,
            genLabelFunc: multiInputFilterLabelGen,
            isCancellable: true,
            isDisabled: false,
          },
          {
            id: 'filter_card_item_country',
            key: 'country',
            label: 'Country Selected',
            componentRenderer: (props) => (
              <MultiSelectCard
                {...props}
                data-hj-suppress
                options={countryOptions || []}
                title="Filter by the nationality of customers"
                placeholder="Select country"
              />
            ),
            updateFuntion: updateMetaDataFilters,
            genLabelFunc: multiSelectFilterLabelGen,
            isCancellable: true,
            isDisabled: false,
          },
          {
            id: 'filter_card_item_dob',
            key: 'dob',
            label: 'Date Of Birth',
            componentRenderer: (props) => (
              <DatePickerCard data-hj-suppress {...props} title="Date Of Birth" hideCustomRanges />
            ),
            updateFuntion: updateMetaDataFilters,
            genLabelFunc: dateFilterLabelGen,
            isCancellable: true,
            isDisabled: false,
          },
        ],
      },
      {
        id: 'transactionMetadataFilters',
        name: 'Transaction Metadata Filters',
        filters: get(filterConfig, 'transactionMetadataFilters', []).map((metadataFilter) => ({
          id: metadataFilter.id,
          label: metadataFilter.label,
          key: metadataFilter.key,
          componentRenderer: (props) => (
            <MultiSelectCard
              {...props}
              options={metadataFilter.options}
              title={metadataFilter.title}
              placeholder={metadataFilter.placeholder}
            />
          ),
          isCancellable: true,
          updateFuntion: updateTransactionMetadataFilters,
          genLabelFunc: multiSelectFilterLabelGen,
        })),
      },
    ];
    let filterListLength = 0;
    availableFilterList.forEach((filter) => {
      filterListLength += filter?.filters?.length || 0;
    });
    return { filterList: availableFilterList, filterListLength };
  } catch (error) {
    Sentry.captureException(`${errorCode.APPLICATION_FECTH_FILTER_ERROR} - ${error}`);
    return { error };
  }
};

export const getTransactionMetadataFilters = (transactionMetadataFilter = {}) => Object
  .values(transactionMetadataFilter)?.reduce((agg, filter) => {
    const key = get(filter, 'filterData.key');
    if (key) {
      agg.push({
        key,
        ...filter?.value,
      });
    }

    return agg;
  }, []);

export const getFiltersFromLocalStorage = ({ filterList, dispatch }) => {
  let finalFilterDetailedList = [];
  // Get the available filter from local storage
  const selectedFilterDetailedListInLS = safeParseJSON(localStorage?.getItem('application_table_filter_detailed_list') || '[]');
  const selectedFilterListInLS = safeParseJSON(localStorage?.getItem('application_table_filter_list') || '[]');

  if (selectedFilterDetailedListInLS && !isEmpty(selectedFilterDetailedListInLS)) {
    finalFilterDetailedList = selectedFilterDetailedListInLS.reduce((acc, availableFilter) => {
      const { details } = availableFilter;
      const matchingFilter = filterList
        .flatMap((filterGroup) => filterGroup.filters)
        .find((filter) => filter.id === details.id);

      if (!isEmpty(matchingFilter)) {
        const updatedFilterDetails = { ...matchingFilter, ...details };
        const { filterLabel, value } = updatedFilterDetails.genLabelFunc(details);
        // Add the updated filter to the accumulator
        acc.push({
          details: updatedFilterDetails,
          filterLabel,
        });
        dispatch(updatedFilterDetails.updateFuntion({
          data: { key: updatedFilterDetails.key, value },
          filterData: updatedFilterDetails,
        }));
      }
      return acc;
    }, []);
    return { selectedFilterListInLS, selectedDetailedFilterInLS: finalFilterDetailedList };
  }
  return { selectedFilterListInLS: [], selectedDetailedFilterInLS: [] };
};
