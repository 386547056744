import React from 'react';
import PropTypes from 'prop-types';

function TransactionIdCell({ transactionId }) {
  return (
    <div>
      <span
        id="application__data_table__transaction_id"
      >
        {transactionId}
      </span>
    </div>
  );
}

TransactionIdCell.propTypes = {
  transactionId: PropTypes.string.isRequired,
};

export default TransactionIdCell;
